/* stylelint-disable max-line-length */

:root {
  --default-font-size: 62.5%;
  --default-font-family: --apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --salutation-text: "Hi! 👋 My name\'s";
  --color-black: #343548;
  --color-white: #f7f7f0;
  --color-purple: #9580ff;
  --font-size-xsmall: 1rem;
  --font-size-small: 2rem;
  --font-size-medium: 4rem;
  --font-size-large: 5rem;
  --font-size-xlarge: 6rem;
}

*::selection {
  background: var(--color-black);
  color: var(--color-white);
}

.theme-dark *::selection {
  background: var(--color-white);
  color: var(--color-black);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
}

body {
  background: var(--color-white);
  color: var(--color-black);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  transition: background, color 0.3s ease-in-out;
}

body.theme-dark {
  background: var(--color-black);
  color: var(--color-white);
}

.container {
  display: grid;
  height: 100vh;
  grid-template-areas: "navigation" "header";
  grid-template-rows: min-content auto;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 5rem;
  grid-area: header;
}

@media only screen and (max-width: 440px) {
  .header {
    padding-left: 1rem;
  }
}

.title {
  display: block;
  margin: 0;
  font-size: 1.875rem;
  font-weight: lighter;
}

.title::before {
  content: "I\'m a ";
  font-size: 1.875rem;
}

@media only screen and (max-width: 440px) {
  .title {
    font-size: var(--font-size-xsmall);
  }

  .title::before {
    font-size: var(--font-size-xsmall);
  }
}

.name {
  margin: 0;
  font-size: var(--font-size-xlarge);
  line-height: 1;
  text-transform: uppercase;
}

.name::before {
  display: block;
  content: var(--salutation-text);
  font-size: var(--font-size-small);
  font-weight: normal;
  text-transform: initial;
}

.name::after {
  display: inline-block;
  content: ".";
  text-indent: -1.875rem;
}

@media only screen and (max-width: 440px) {
  .name {
    font-size: var(--font-size-medium);
  }

  .name::before {
    margin-bottom: 0;
    font-size: var(--font-size-medium);
  }

  .name::after {
    text-indent: -1rem;
  }
}

.contacts {
  display: flex;
  min-height: 3.75rem;
  flex-direction: row-reverse;
  padding: 1rem;
  grid-area: navigation;
}

.contacts ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.contacts li {
  display: inline-block;
  padding-right: 0.25rem;
}

.contacts a {
  position: relative;
  margin: 0 5px;
  color: var(--color-black);
  font-size: var(--font-size-xsmall);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.contacts a::before {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-purple);
  content: "";
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}

.theme-dark .contacts a::before {
  background-color: var(--color-purple);
}

.contacts a:hover::before,
.contacts a:focus::before {
  transform: scaleX(1);
  visibility: visible;
}

.theme-dark .contacts a {
  color: var(--color-white);
}

.contacts a:is(:hover, :focus) {
  text-decoration: none;
}

.toggle-theme {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  overflow: hidden;
  width: 2.625rem;
  height: 2.625rem;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.toggle-theme .moon {
  opacity: 1;
}

.toggle-theme .sun {
  opacity: 0;
}

.toggle-theme span {
  position: absolute;
  display: block;
  width: 2rem;
  height: 2rem;
  font-size: var(--font-size-small);
  transition: opacity 0.3s ease-in-out;
}

.theme-dark .toggle-theme .moon {
  opacity: 0;
}

.theme-dark .toggle-theme .sun {
  opacity: 1;
}

.wave {
  display: inline-block;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-name: wave;
  transform-origin: 70% 70%;
}

@keyframes wave {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}
